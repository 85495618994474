.wrap-home {
    background: url('../images/background.jpg') no-repeat center center fixed,
        url('../images/background-2.png') no-repeat bottom left;
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    background-size: cover;
}
.home {
    background: url('../images/background-2.png') no-repeat bottom left;
    background-size: 50vw;
    height: 100vh;
    position: relative;
    &__nav {
      left: 50%;
      position: absolute;
      top: 18vh;
      transform: translateX(-50%);
      width: 70%;

      @media (max-width: breakpoint-min(md, $container-max-widths)) {
        left: 50%;
        top: 5vh;
        width: 80%;
      }
    }
    &__items {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        flex-wrap: wrap;
        @media (max-width: breakpoint-min(sm, $container-max-widths)) {
          flex: 1;
          flex-direction: column;
          margin: 0 auto;
          flex-wrap: nowrap;
        }

    }
    &__item {
      margin-bottom: 15px;
      flex-basis: 45%;
      @media (min-width: breakpoint-min(sm, $container-max-widths)) {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 15px;
      }
      img {
        height: 100%;
        transition: transform .25s;
        @media (min-width: breakpoint-min(md, $container-max-widths)) {
          max-height: 80px;
        }
        @media (min-width: breakpoint-min(sm, $container-max-widths)) and (max-width: breakpoint-max(md, $container-max-widths)) {
          max-height: 50px;
        }
        &:hover {
          transform: scale(.96);
        }
      }
      a {
        display: block;
        max-height: auto;
        text-align: right;
        transition: .25s ease;
      }
      &:nth-child(even) {
        flex-basis: 55%;
        a {
          text-align: left;
        }
      }
    }
    &__footer {
        position: absolute;
        bottom: 1em;
        right: 1em;
        text-align: right;
        color: $white;
        font-size: .8rem;
        @media (min-width: breakpoint-min(md, $container-max-widths)) {
          right: 4em;
        }
        .social {
          display: block;
          margin-top: .8em;
          a {
            display: inline-block;
            width: 20px;
            height: 20px;
            overflow: hidden;
            fill: $white;
            padding: 0;
            margin: 0;
            &:first-child {
              margin-right: 12px;
            }
          }
        }
        h3 {
            text-transform: uppercase;
            font-size: 1.2rem;
        }
        a {
            color: inherit;
        }
    }
}

.thanks {
  color: $white;
  font-size: 1.3rem;
  margin-bottom: 3em;
}
