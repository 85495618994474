.concert {
    background: $concert-background;
    padding-top: $menu-space;
    @media (max-width: breakpoint-min(md, $container-max-widths)) {
        padding-top: $menu-space-mobile;
    }

    .video_wrapper {
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        .video_item {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
        }
    }
}