$base-height: 880px;
.wrap-dj {
    background: $black;
}
.dj {
    background: $dj-background;
    color: $dj-font;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
    padding-top: $menu-space;
    @media (max-width: breakpoint-min(md, $container-max-widths)) {
        padding-top: $menu-space-mobile;
    }

    .staff {
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: right;
        display: flex;
        align-items: center;
        padding: 10% 0;
        &--tito {
            background-image: url('../images/dj-tito.jpg');
            background-position: left;
            position: relative;
            .title {
                color: $dj-title-tito;
            }
            @media (max-width: breakpoint-min(md, $container-max-widths)) {
              &:before {
                content: '';
                display: block;
                background-color: rgba(0, 0, 0, 0.6);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
              }
            }
        }
        &--delfina {
            background-image: url('../images/dj-delfina.jpg');
            position: relative;
            .title {
                color: $dj-title-delfina;
            }
            @media (max-width: breakpoint-min(md, $container-max-widths)) {
              &:before {
                content: '';
                display: block;
                background-color: rgba(0, 0, 0, 0.6);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
              }
            }
        }
    }

    // .gallery,
    // .service {
    //     &__img {
    //         height: $base-height;
    //     }
    // }

    &-service {
        // &__img {
        //     height: $base-height / 2;
        // }
        align-items: center;
        &__text {
            // display: flex;
            // justify-content: center;
            // flex-direction: column;
            // height: 100%;
            // padding: 0 4em;
            width: 70%;
            margin: 0 auto;
            .title {
                color: $dj-title-service;
            }
            @media (max-width: breakpoint-min(md, $container-max-widths)) {
                width: auto;
                margin: 0;
                padding: 0 $spacer;
            }
        }
    }
    .contact {
        text-align: center;
    }
}
