.wrap-convention {
    background: $white;
}
.convention {
    padding-top: $menu-space;
    @media (max-width: breakpoint-min(md, $container-max-widths)) {
        padding-top: $menu-space-mobile;
    }

    .led {
        color: $white;
        background-image: url('../images/convention-4.jpg');
        background-size: cover;
        padding: 5em 0;
        margin-bottom: 4em;
    }

    .details {
        background-color: $sct-gray;
        padding: 5em 0;
        font-size: .9rem;
        @media (min-width: breakpoint-min(md, $container-max-widths)) {
            background-image: url('../images/convention-details.png');
            background-repeat: no-repeat;
            background-size: 15%;
            background-position: right 14.28571% bottom 4em;
        }

        ul, li {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: inherit;
        }
        h4,
        ul {
            font-size: inherit;
            margin-right: 30px;
            @media (max-width: breakpoint-min(md, $container-max-widths)) {
                margin: 0 1rem 1rem 1rem;
            }
        }
    }

    .traduccion {
        @media (max-width: breakpoint-min(md, $container-max-widths)) {
            width: 60%;
            margin: 0 auto;
        }
    }

    .member {
      img {
        width: 100%;
      }
    }
}
