@font-face {
    font-family: 'DIN Regular';
    src: url('../fonts/PFDin/PFDinTextUniversal-Regular-webfont.eot');
    src: local('../fonts/PFDin/PFDinTextUniversal-Regular-webfont'),
         local('../fonts/PFDin/PFDinTextUniversal-Regular-webfont'),
         url('../fonts/PFDin/PFDinTextUniversal-Regular-webfont.ttf') format('truetype'),
         url('../fonts/PFDin/PFDinTextUniversal-Regular-webfont.svg#font') format('svg');
}

.text-center {
    text-align: center;
}

.img-responsive {
    max-width: 100%;
    width: 100%;
}

.row {
    margin-bottom: 2rem;
}

.nowrap {
    white-space: nowrap;
}
.add-margin {
    margin: 2.5rem 0;
}

.block-text {
    @media (max-width: breakpoint-min(md, $container-max-widths)) {
        padding: 0 $spacer;
    }
}
.block-text__space {
  padding: 0 $spacer;
}

input, textarea, select {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: inherit !important;
    -webkit-box-shadow: 0 0 0px 1000px inherit inset;
    -webkit-text-fill-color: inherit !important;
    transition: all 5000s ease-in-out 0s;
  }
}
