.contact {
  text-align: center;
  color: $black;
  font-size: .8rem;
  margin: 0 auto;
  box-sizing: border-box;
  .contact__field {
    display: block;
  }
  &__inner {
    padding: $spacer;
    @media (min-width: breakpoint-min(md, $container-max-widths)) {
      padding: 30px 40px 40px 40px;
    }
  }
  &__form {
    @media (min-width: breakpoint-min(md, $container-max-widths)) {
      width: 90%;
      max-width: 600px;
    }
    margin: 0 auto 2em auto;
    input, textarea {
      color: $field-light;
      width: 100%;
      border: none;
      background-color: $field-bkg-light;
      padding: 5px 15px;
      resize: none;
      outline: none !important;

      &::-moz-placeholder { /* Firefox 19+ */
        color: $field-placeholder-light;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $field-placeholder-light;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $field-placeholder-light;
      }
      &::placeholder {
        color: $field-placeholder-light;
      }
    }
    &--hide {
      display: none;
    }
  }
  &__field,
  &__textarea {
    position: relative;
  }

  &__field {
    @media (min-width: breakpoint-min(md, $container-max-widths)) {
      width: 50%;
    }
    &--with-icon {
      input {
        padding-left: 43px;
      }
      &:before {
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid $white;
        content: '';
        display: block;
        height: 14px;
        left: 15px;
        position: absolute;
        width: 14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &--selected {
      &:before {
        background-color: $white;
      }
    }
  }
  &__textarea {
    width: 100%;
    textarea {
      height: 5rem;
    }
  }
  &__submit {
    text-align: right;
    button {
      background: none;
      border: none;
      color: $black;
      text-transform: uppercase;
      cursor: pointer;
      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }
  p {
      margin: 0;
      a,
      span {
          color: inherit;
          padding: 0 10px;
          &.contact__phone {
              padding-right: 0;
          }
          display: inline-block;
      }
  }
  .title {
      font-size: 1.5em;
      text-transform: uppercase;
      margin-bottom: 20px;
  }
  &--dark {
    background-color: $black;
    color: $field-dark;
    &.contact {
      .contact__submit {
        button {
          color: $field-dark;
        }
      }
      .contact__form {
        input, textarea {
          color: $field-dark;
          background-color: $field-bkg-dark;
          &::-moz-placeholder { /* Firefox 19+ */
            color: $field-placeholder-dark;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: $field-placeholder-dark;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: $field-placeholder-dark;
          }
          &::placeholder {
            color: $field-placeholder-dark;
          }
        }
      }
    }
  }

  &--live {
    background-color: $live-background;
    color: $white;
    &.contact {
      .contact__submit {
        button {
          color: $field-dark;
        }
      }
      .contact__form {
        input, textarea {
          color: $field-light;
          &::-moz-placeholder { /* Firefox 19+ */
            color: $field-placeholder-light;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: $field-placeholder-light;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: $field-placeholder-light;
          }
          &::placeholder {
            color: $field-placeholder-light;
          }
        }
      }
    }
  }
  .map {
    iframe {
      width: 100%;
      height: 200px;
    }
    padding: 20px 0;
  }
}

.thanks {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > div {
    background-color: rgba(0, 0, 0, 0.85);
    box-shadow: 0 2px 20px rgba($color: #fff, $alpha: 0.8);
    width: 90%;
    height: 90%;
    position: relative;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: 'x';
      display: block;
      width: 30px;
      height: 30px;
      color: $white;
      right: 20px;
      top: 20px;
      position: absolute;
      text-transform: uppercase;
    }
  }
}
