.live {
  background-color: $live-background;
  padding-top: $menu-space;
  color: $live-font;
  @media (max-width: breakpoint-min(md, $container-max-widths)) {
      padding-top: $menu-space-mobile;
  }
}

.photos {
  background: $live-photos-background url('../images/live-photos-bkg.png');
  padding: $spacer $spacer*2;
  @media (max-width: breakpoint-min(md, $container-max-widths)) {
    padding: $spacer;
  }
  &__wrap {
    background-color: $white;
    padding: $spacer;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 90%;
    @media (max-width: breakpoint-min(md, $container-max-widths)) {
      flex-direction: column;
      padding: $spacer/2;
      width: 100%;
    }
  }
  &__item {
    padding: $spacer/2;
    width: 25%;
    @media (max-width: breakpoint-min(md, $container-max-widths)) {
      width: 100%;
    }
  }
  &__img {
    width: 100%;
  }
}
