.header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    padding: 1em 7.14286% 0 7.14286%;
    z-index: 1;
    height: $menu-space;
    @media (max-width: breakpoint-min(md, $container-max-widths)) {
        padding: 2% 1.2rem;
        height: $menu-space-mobile;
        justify-content: space-between;
    }
    &--scrolling {
        height: $menu-space-mobile;
        @media (min-width: breakpoint-min(md, $container-max-widths)) {
          padding: .2em 7.14286% 0 7.14286%;
        }
        .header__logo {
          @media (min-width: breakpoint-min(md, $container-max-widths)) {
              width: 120px;
            }
        }
    }

    li, ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li {
        display: inline-block;
        a {
            padding: 0 10px;
        }
        &.menu__last {
          flex: 1;
        }
    }
    a {
        color: inherit;
    }
    .menu {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin: 0 1em;
        position: relative;
        ul {
            display: flex;
        }
        &__toogle {
          display: none;
          background: none;
          width: 36px;
          height: 30px;
          border: 0;
          border-bottom: 2px solid $gray-800;
          border-top: 2px solid $gray-800;
          overflow: hidden;
          text-indent: -999px;
          outline: none;
          background: linear-gradient(
            to bottom,
            transparent 0, transparent 48%,
            $gray-800 48%, $gray-800 52%,
            transparent 52%, transparent 100%
          );
        }
        &__nav {
          width: 100%;
          display: block;
        }
        @media (max-width: breakpoint-min(md, $container-max-widths)) {
          flex: 0;
          &__toogle {
            display: block;
          }
          &__nav {
            align-self: flex-start;
            flex-direction: column;
            position: absolute;
            background-color: rgba( $white, .9 );
            top: 40px;
            right: 0;
            width: 116px;
            a {
              padding: 10px 20px;
              display: block;
            }
          }
          &__nav--hidden {
            display: none;
          }
          ul {
            flex-direction: column;
          }
        }
    }

    &__logo {
        width: 240px;
        transition: .25s ease all;
        @media (max-width: breakpoint-min(md, $container-max-widths)) {
            width: 150px;
        }
    }

    .social {
      a {
        display: inline-block;
        width: 20px;
        height: 20px;
        overflow: hidden;
        fill: $black;
        padding: 0;
        margin: 0 5px;
        svg {
          margin-top: -4px;
        }
        @media (max-width: breakpoint-min(md, $container-max-widths)) {
          margin: 10px 20px;
        }
      }
    }

    &--dj {
      background: rgba( $dj-background, .9 );
      .social {
          a {
              fill: $white;
          }
      }
      @media (max-width: breakpoint-min(md, $container-max-widths)) {
        .menu__toogle {
          border-bottom-color: $white;
          border-top-color: $white;
          background: linear-gradient(
            to bottom,
            transparent 0, transparent 48%,
            $white 48%, $white 52%,
            transparent 52%, transparent 100%
          );
        }
        .menu__nav {
          background-color: rgba( $black, .9 );
        }
      }
    }
    &--concert {
      background: rgba( $concert-background, .9 );
      @media (max-width: breakpoint-min(md, $container-max-widths)) {
        .menu__nav {
          background-color: rgba( $concert-background, .9 );
        }
      }
    }
    &--convention {
      background: rgba( $white, .9 );
    }
    &--live {
      background: rgba( $live-background, .9 );
    }
}
